/* Temp CSS until theming can be handled. */
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Montserrat', 'Arial', 'Helvetica', sans-serif !important;
}

.back-menu {
  position: absolute !important;
  top: 10px;
  left: 10px;
  z-index: 999;
}

.login-logo {
  width: 80%;
}

.header a {
  color: #2d393b !important;
  font-weight: 500 !important;
}

p.test-description {
  color: #2d393b !important;
  font-weight: 400 !important;
  margin-top: 0.5rem !important;
}

/* Enabled toggle */
#testForm .ui.toggle.checkbox input:checked ~ label:before  {
  background-color: #E37463 !important;
}

 /*Add new step, assertion and escalation buttons */
.ui.secondary.button {
  background-color: #091F44 !important;
}

.ui.primary.button:hover,
.ui.secondary.button:hover {
  background-color: #E37463 !important;
}

/* Changing text editor colors to match palette */
.jsoneditor {
  border: 1px solid #091F44 !important;
}

.jsoneditor-menu {
  background-color: #091F44 !important;
  border-bottom: 1px solid #091F44 !important;
}

.ace-solarized-dark {
  background-color: #091F44 !important;
  color: #fff !important; 
}

.ace-solarized-dark .ace_gutter,
.ace-solarized-dark .ace_gutter_active-line {
  background-color: #091F44 !important; 
}

.card .ui.grid {
  align-items: center;
}

.card .icon-container a {
  padding-right: 25px;
}

.grey.icon.check.button {
  padding-right: 38px;
}
.grey.icon.file.button {
  padding-right: 38px;
}

/* Confirm button on settings page */
.ui.button {
  background-color: #468189 !important; 
  color: #fff !important;
}

.ui.button.white {
  color: rgba(255, 255, 255, 0.6) !important;
  background: transparent none !important;
  font-weight: 400 !important;
  border-radius: 0.28571429rem !important;
  text-transform: none !important;
  text-shadow: none !important;
  box-shadow: 0 0 0 1px rgb(255 255 255 / 35%) inset !important;
}

.cards .ui.button {
  font-family: 'Montserrat', 'Arial', 'Helvetica', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
}

.ui.cards > .ui.button.buttonTag {
  text-transform: none;
}

.cards .header {
  font-size: 1.3em;
  font-weight: 600;
}

.ui.cards > .red.card,
.ui.red.card,
.ui.red.cards > .card {
  box-shadow: 0 0 0 1px #d4d4d5, 0 1px 3px 0 #d4d4d5 !important;
  border-left: 10px solid #c24734;
}

.ui.cards > .green.card,
.ui.green.card,
.ui.green.cards > .card {
  box-shadow: 0 0 0 1px #d4d4d5, 0 1px 3px 0 #d4d4d5 !important;
  border-left: 10px solid #21ba45; /* was #34c28e */
}

.ui.cards > .yellow.card,
.ui.yellow.card,
.ui.yellow.cards > .card {
  box-shadow: 0 0 0 1px #d4d4d5, 0 1px 3px 0 #d4d4d5 !important;
  border-left: 10px solid #f9b131;
}

.ui.cards > .grey.card,
.ui.grey.card,
.ui.grey.cards > .card {
  box-shadow: 0 0 0 1px #d4d4d5, 0 1px 3px 0 #d4d4d5 !important;
  border-left: 10px solid #767676;
}

.green-text {
  color: #21ba45 !important; /* was #34c28e */
}

.red-text,
.ui.cards i.red.icon {
  color: #c24734 !important;
}

.ui.primary.button:not(.basic) {
  background-color: #468189; /* was #34afc2 */
  font-family: 'Montserrat', 'Arial', 'Helvetica', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  /* min-width: 200px; */
}

.ui.green.button:not(.basic) {
  background-color: #008071 !important;
}

.ui.red.button:not(.basic) {
  background-color: #b71f26 !important;
}

/* was teal. this was not being used semantically like .ui.teal.message on line 204 */
.ui.blue-green.button,
.ui.blue-green.buttons .button,
.ui.basic.blue-green.button.back-menu {
  background-color: #468189!important; /* was #34afc2 */
  color: #fff !important;
  box-shadow: none !important;
}

/* Was light-blue. */
.ui.terra-cotta.button,
.ui.terra-cotta.buttons .button {
  background-color: #468189 !important; /* was #79c7d4 */
  color: #fff !important;
}

/* Analytics and most of the other buttons. Was "blue" */
.ui.blue-green.button,
.ui.blue-green.buttons .button { 
  background-color: #468189 !important; /* was not on this style sheet. was #2185D0 */
  color: #fff; 
}

.ui.oxford-blue.button {
  background-color: #091F44 !important; 
  color: #fff;
}

/* was dark-blue */
.ui.opal.button,
.ui.opal.buttons .button {
  background-color: #9DBEBB !important; /* was #114f82 */
  color: #fff !important;
}

/* Styles the "No resolution logs" message on ViewTest.tsx page.
/* blue-green was not an assignable color type for semanticsUI so left as teal for now, but updated colors */
/* So test messages are either red, green or the new blue-green "brand" color */
.ui.teal.message {
  background-color: #dfedef !important;
  color: #468189 !important; 
  box-shadow: 0px 0px 0px 1px #468189 inset !important;
}

.ui.red.message:not(.basic) {
  background-color: #f9d0d1 !important;
  color: #c24734 !important;
  box-shadow: 0 0 0 1px #c24734 inset, 0 0 0 0 transparent !important;
}

.ui.red.message .header {
  color: #c24734 !important;
}

.ui.green.message {
  background-color: #dff4eb !important;
  color: #21BA45  !important; /* was #34c28e */
  box-shadow: 0 0 0 1px #21BA45  inset, 0 0 0 0 transparent !important; /* was #34c28e */
}

.ui.green.message .header {
  color: #21BA45  !important; /* was #34c28e */
}

.ui.icon.message.small > .icon:not(.close) {
  font-size: 15px;
}

#testGrid .ui.selection.dropdown {
  margin-bottom: 1rem;
}

#testGrid .ui.input {
  height: 2.71428571em;
  width: 16em;
}

@media (max-width: 768px) {
  .card .ui.column.grid > [class*='seven wide'].column,
  .ui.grid > .column.row > [class*='seven wide'].column,
  .ui.grid > .row > [class*='seven wide'].column,
  .ui.grid > [class*='seven wide'].column {
    width: 100% !important;
  }
  .card .ui.column.grid > [class*='six wide'].column,
  .ui.grid > .column.row > [class*='six wide'].column,
  .ui.grid > .row > [class*='six wide'].column,
  .ui.grid > [class*='six wide'].column {
    width: 100% !important;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
  .card .ui.column.grid > [class*='two wide'].column,
  .ui.grid > .column.row > [class*='two wide'].column,
  .ui.grid > .row > [class*='two wide'].column,
  .ui.grid > [class*='two wide'].column {
    width: 50% !important;
  }
  .card .icon-container a {
    padding-left: 25px;
    padding-right: 0;
  }
}
