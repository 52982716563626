.sextant {
  .ui.statistics {
    .statistic {
      padding: 2em 0;
      border: 15px solid white;
      > .value {
        font-family: Montserrat !important;
        font-weight: 600 !important;
        margin-bottom: 10px;
      }
      > .label {
        font-family: Montserrat;
        color: #2d393b;
        font-weight: 300 !important;
      }
    }
  }
  .ui.statistics:not(.holdout) {
    .statistic {
      background: rgb(237, 238, 238);
      > .value {
        color: #3dbeaf;
      }
    }
  }
  .ui.card {
    .content:not(.extra) {
      padding: 35px 25px !important;
    }
    text-align: center;
    .header {
      text-transform: uppercase;
      font-family: Montserrat;
      font-weight: 300 !important;
      font-weight: 600;
      font-size: 20px !important;
      line-height: 32px;
      text-align: center;
      color: #2d393b;
    }
  }
  .ui.card > .extra,
  .ui.cards > .card {
    & > .extra {
      background: #3dbeaf;
      font-family: Montserrat;
      text-transform: uppercase;
      font-weight: 300;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.05em;
      line-height: 26px;
      transition: opacity 0.1s ease;
      text-align: center;
      color: #f8f8f8;
      opacity: 0.8;
      &:hover {
        opacity: 1;
        cursor: pointer;
        color: rgb(255, 255, 255);
      }
    }
    .description {
      padding: 15px 0 0 0;
    }
  }
}

.primary-application,
.compass {
  // &, .sextant {
  //   .header-strip {
  //     background: repeating-linear-gradient(
  //       110deg,
  //       #f1f1f1,
  //       #f1f1f1 2px,
  //       #f7f9ff 2px,
  //       #f7f9ff 12px
  //     ) !important;
  //     border-bottom: 1px solid #f1f1f1;
  //   }
  // }

  h3 {
    font-weight: 400 !important;
  }
  .header-strip {
    background: repeating-linear-gradient(110deg, #f1f1f1, #f1f1f1 2px, #f7fffd 2px, #f7fffd 12px);
    border-bottom: 1px solid #f1f1f1;
  }
  h1.ui.header {
    font-family: Montserrat;
    font-weight: 200;
    font-size: 44px;
    letter-spacing: 0.05em;
    line-height: 70px;
    text-align: center;
    color: #2d393b;
    text-transform: uppercase;
    .sub.header {
      font-family: Lato;
      font-weight: normal;
      letter-spacing: normal;

      // font-size: 20px !important;
      color: #3d494b;
      text-transform: none;
    }
  }
  .card-container {
    // margin: 25px 0;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, 350px);
    // flex-flow: row wrap;
    // gap: 15px;
  }
  .nav-card {
    border-radius: 10px;
    border: rgba(0, 0, 0, 0.1) 1px solid;
    background: #fff;
    padding: 25px 25px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    transition: box-shadow 0.2s ease-out;
    // align-content: center;
    display: flex;
    // flex-direction: column;
    // justify-content: center;
    // ::after {
    //   box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    //   opacity: 0;
    //   transition: opacity 0.3s ease-in-out;
    // }
    // :hover::after {
    //   opacity: 1;
    // }
    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      transition: box-shadow 0.2s ease-in;
      cursor: pointer;
      transition: box-shadow 0.2s ease-in;
    }
    > img {
      height: 30px;
      width: 30px;
    }
    > .card-name {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      margin-left: 20px;
      font-family: Lato;
      font-weight: normal;
      font-size: 18px;
      text-align: left;
      color: #6e7277;
    }
  }
}

.sonar-header-strip {
  h1 {
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 30px;
  }
  h1.ui.header {
    line-height: 70px;
    font-weight: 200;
    text-align: center;
    color: #2d393b;
    text-transform: uppercase;
    z-index: 1;
    .sub.header {
      font-family: Lato;
      font-weight: normal;
      letter-spacing: normal;

      // font-size: 20px !important;
      color: #3d494b;
      text-transform: none;
    }
  }

  // background: repeating-linear-gradient(110deg, #f1f1f1, #f1f1f1 2px, #f7fffd 2px, #f7fffd 12px);
  // border-bottom: 1px solid #f1f1f1;
}

.FullWidthWrapper {
  position: relative;
  display: flex;
  width: 100%;
}

.sub-heading {
  padding: 0 !important;
  height: 90vh;
}

header {
  height: 100px;
  max-height: 100px;
  padding: 30px;
  position: relative;

  h1 {
    margin: 0;
    position: absolute;
    margin-left: 60px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
.animate-width {
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}
.portalNav {
  margin-top: 25vh;
  & .ui.list .list > .item:first-child,
  .ui.list > .item:first-child,
  ol.ui.list li:first-child,
  ul.ui.list li:first-child {
    margin-top: 0 !important;
  }
  .item.navOption {
    img {
      height: 50px;
    }
    padding: 20px 0 20px 10px !important;
    font-family: Lato;
    font-size: 18px;
    color: #939498 !important;
    transition: color 0.1s ease, border 0.1s ease;
    text-align: center;
    &.active {
      color: #3dbeaf !important;
      font-weight: bold;
      background: #f8f8f8 !important;
      border-left: #3dbeaf 5px solid;
      padding: 20px 0 20px 5px !important;
    }
    &:hover {
      color: #3dbeaf !important;
      border-left: #3dbeaf 5px solid;
      cursor: pointer;
    }
  }
}

.headerSearch > {
  .ui.input {
    width: 100% !important;
    input {
      // border: 0;
      height: 50px !important;
      border-radius: 10px !important;
      background: #fff !important;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1) !important;
    }
  }
}

.mainContent {
  height: calc(100vh - 135px) !important;
  overflow-y: hidden;
}

#userDashboard {
  div.extra.content:hover {
    opacity: 1 !important;
    transition: opacity 0.2s ease;
  }
  .header-strip {
    background: repeating-linear-gradient(110deg, #f1f1f1, #f1f1f1 2px, #f7fffd 2px, #f7fffd 12px);
    border-bottom: 1px solid #f1f1f1;
  }

  .dataTitle {
    margin-top: 25px;
    font-family: Lato;
    font-weight: 800;
    font-size: 14px;
    text-transform: lowercase;
  }
  .ui.card {
    height: 100%;
    .description {
      text-align: center;
    }
    .header {
      font-family: Montserrat;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 20px;
      letter-spacing: 0.05em;
      line-height: 26px;
      text-align: left;
      color: #2d393b;
    }
    .extra {
      background: #3dbeaf;
      font-family: Montserrat;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.05em;
      line-height: 26px;
      text-align: center;
      text-transform: uppercase;
      transition: background 0.1s ease;
      &:hover {
        background: #329b8f;
      }
      & a {
        color: #f8f8f8;
      }

      &.disabled {
        background: #adadad;
      }
    }
  }
}

.sextant-wrapper {
  align-content: center;
  display: flex;
  flex-direction: column;
  height: 350px;
  justify-content: center;
  margin-bottom: 25px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
}
